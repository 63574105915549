<template>
  <base-section
    id="mobile"
    class="secondary"
  >
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <base-section-heading
            align="left"
            subtitle="A GUARANTEED BUYER FOR YOUR HSI"
            tag="h2"
            title="HSI Buy-Back"
          >
            The Icosa protocol will buy any and all HSI NFT's created by the Hedron protocol at a rate determined by it's borrowable amount of HDRN and the HEX share rate at the time of sale. These HSI's are then detokenized and borrowed against by the contract to be sent to the Hedron liquidation auction 90 days later.
          </base-section-heading>

          <v-row>
            <v-col
              v-for="([icon, title], i) in features"
              :key="i"
              cols="12"
              md="6"
            >
              <div class="d-flex align-center">
                <base-avatar
                  :icon="icon"
                  class="mr-6"
                  color="primary"
                  dark
                  outlined
                />

                <v-responsive max-width="112">
                  <base-subtitle
                    :title="title"
                    class="text--primary"
                  />
                </v-responsive>
              </div>
            </v-col>
          </v-row>
        </v-col>

        <v-col
          cols="12"
          md="6"
        >
          <base-img
            :src="require('@/assets/hsi.png')"
            class="mx-auto"
            contain
            height="400"
            max-width="300"
          />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionMobile',

    data: () => ({
      features: [
        ['mdi-clock-plus', '10% bonus for 5,555 day stake lengths'],
        ['mdi-numeric-9-plus-box', '1% - 10% bonus for stakes with a HDRN launch bonus'],
        ['mdi-gavel', 'Stakes are borrowed against and sent to auction'],
        ['mdi-cash-register', 'All borrowed HDRN is given to the ICSA staking pool'],
      ],
    }),
  }
</script>
